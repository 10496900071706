import React, { Component } from "react";
import { Modal, Button, Form, Input, Spin } from "antd";
import { RecommendedSize, SvgIcon } from "../../../../components/common";

import "./add-slider-popup.scss";
import { connect } from "react-redux";
import {
  createHomeSlider,
  createLoadingSelector,
  getHomePageLists,
  resetStoreState,
} from "../../../../redux/action";
import { serverApis } from "../../../../constants/server-apis";
import { showAlert } from "../../../../common/alert-messages";
import { isObjectEmpty, isObjectNotEmpty } from "../../../../common/utils";

class AddSliderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      file: null,
      requestObject: this.emptyRequestObject(),
    };
    this.onChangeImage = this.onChangeImage.bind(this);
    this.onresetImage = this.onresetImage.bind(this);
  }
  formRef = React.createRef();

  emptyRequestObject = () => {
    return {
      url: "home",
      image_file: null,
    };
  };

  onChangeImage(event) {
    const requestObject = this.state.requestObject;
    requestObject.image_file = event.target.files[0];
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      requestObject,
    });

    event.target.value = null;
  }

  onresetImage(event) {
    event.preventDefault();
    const requestObject = this.state.requestObject;

    requestObject.image_file = null;

    this.setState({ file: null, requestObject });
  }

  showModal = (event) => {
    this.setState(
      {
        visible: true,
        requestObject: this.emptyRequestObject(),
        file: null,
      },
      () => {
        this.formRef.current.setFieldsValue({
          url: this.emptyRequestObject().url,
        });
      }
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      requestObject: this.emptyRequestObject(),
      file: null,
    });

    this.formRef.current.setFieldsValue({
      url: this.emptyRequestObject().url,
    });
  };

  onTextChange = (event) => {
    let requestObject = this.state.requestObject;
    requestObject["url"] = event.target.value;

    this.setState({ requestObject });
  };

  createHomeSlider = () => {
    if (this.state.requestObject.image_file === null) {
      showAlert.error("Please add an image.");
    } else if (isObjectEmpty(this.state.requestObject.url)) {
      showAlert.error("Please enter an url.");
    } else {
      this.props.createHomeSlider(this.state.requestObject);
    }
  };

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.createHomeSliderSuccessMessage)) {
      showAlert.success(this.props.createHomeSliderSuccessMessage);
      this.props.resetStoreState(serverApis.createHomeSlider.actionName);

      this.props.getHomePageLists();

      this.handleCancel();
    }
  }

  render() {
    const { visible } = this.state;
    return (
      <React.Fragment>
        <div
          className="addNewImage"
          style={{ minHeight: 60 }}
          onClick={(event) => {
            event.stopPropagation();
            this.showModal();
          }}
        >
          <div className="addNewImage-link">
            <SvgIcon name="plus-circle" viewbox="0 0 19 19" />
            ADD MORE SLIDE
          </div>
        </div>
        <Modal
          visible={visible}
          title="Create New Slide"
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closeIcon={<SvgIcon name="close" viewbox="0 0 298.667 298.667" />}
          footer={[
            <Button
              key="button"
              type="primary"
              htmlType="submit"
              form={"form"}
              block
              onClick={this.createHomeSlider}
            >
              {" "}
              CREATE slide{" "}
            </Button>,
          ]}
          className="modal-cms addimageurl-modal"
        >
          <Spin spinning={this.props.isFetchingData} size="large">
            <div className="create-collection-modal">
              <Form layout="vertical" ref={this.formRef} id={"form"}>
                <div className="d-flex">
                  <div className="imgthumbnail-big w-100 mr-0">
                    <div className="imgthumbnail-big-inner">
                      {this.state.file && (
                        <div className="removethumb-coverimg">
                          <div
                            className="close-icon"
                            onClick={this.onresetImage}
                          >
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                      )}
                      <div className="uploadcoverthumbnail">
                        <div className="uploadcoverthumbnail-link">
                          <input type="file" onChange={this.onChangeImage} />
                          <SvgIcon
                            name="plus-circle"
                            viewbox="0 0 19 19"
                          />{" "}
                          UPLOAD IMAGE
                        </div>
                      </div>
                      {this.state.file && (
                        <div>
                          <div className="editcover-img">
                            <SvgIcon name="pencil" viewbox="0 0 16.19 16.19" />{" "}
                            Change Image
                            <input type="file" onChange={this.onChangeImage} />
                          </div>
                        </div>
                      )}
                      {this.state.file && (
                        <div className="uploadedthumbimg-wrape">
                          <img
                            className="uploadedthumbimg-big"
                            alt="uploadedthumbimg-big"
                            src={this.state.file}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <RecommendedSize width="1920" height="572" />

                <div className="mt-5">
                  <label htmlFor="slide_url"> URL</label>
                  <div className="d-flex custom-url align-items-center mb-3 w-100">
                    <div className="url">www.tekweld.com/</div>
                    <div className="sub-url w-100">
                      <Form.Item className="m-0" id="url" name="url">
                        <Input
                          name="url"
                          size="large"
                          placeholder="Enter Slide URL"
                          value={this.state.requestObject.url}
                          onChange={this.onTextChange}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.createHomeSlider.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  homePageLists: state.homePageLists,
  createHomeSliderSuccessMessage: state.createHomeSliderSuccessMessage,
});

export default connect(mapStateToProps, {
  resetStoreState,
  createHomeSlider,
  getHomePageLists,
})(AddSliderPopup);
